import React from "react";
import LessonLayout from "~/layouts/lesson";
import H1 from "~/components/Typography/H1";
import Button from "~/components/button";


const Page = () => {
  return (
      <LessonLayout
        current_step={1}
        lesson="Supplemental Materials"
        color={"ms"}
        total_count={1}
        module_title=""
      >
        <H1>Teacher Supplemental Materials</H1>

        <div className="grid grid-rows-3 grid-flow-col gap-6 mt-10">
          <Button
            action="primary"
            className="pl-0"
            color="purple"
            href="/files/ms/sm/Teacher_Guide_MS_all_lessons.pdf"
            icon="download"
            title="All Lessons"
          >
            Teacher guide all lessons
          </Button>
          <Button
            action="primary"
            className="pl-0"
            color="purple"
            href="/files/ms/sm/Teacher_Guide_MS_Lesson_1.pdf"
            icon="download"
            title="Lesson 1"
          >
            Teacher guide lesson 1
          </Button>
          <Button
            action="primary"
            className="pl-0"
            color="purple"
            href="/files/ms/sm/Teacher_Guide_MS_Lesson_2.pdf"
            icon="download"
            title="lesson 2"
          >
            Teacher guide lesson 2
          </Button>
          <Button
            action="primary"
            className="pl-0"
            color="purple"
            href="/files/ms/sm/Teacher_Guide_MS_Lesson_3.pdf"
            icon="download"
            title="lesson 3"
          >
            Teacher guide lesson 3
          </Button>
          <Button
            action="primary"
            className="pl-0"
            color="purple"
            href="/files/ms/sm/Teacher_Guide_MS_Lesson_4.pdf"
            icon="download"
            title="lesson 4"
          >
            Teacher guide lesson 4
          </Button>
          <Button
            action="primary"
            className="pl-0"
            color="purple"
            href="/files/ms/sm/Teacher_Guide_MS_Lesson_5.pdf"
            icon="download"
            title="Lesson 5"
          >
            Teacher guide lesson 5
          </Button>
          <Button
            action="primary"
            className="pl-0"
            color="purple"
            href="/files/hs/sm/test_bank_questions.docx"
            icon="download"
            title="test bank"
          >
            Test Bank Questions
          </Button>
        </div>
      </LessonLayout>
  )
}

export default Page;
